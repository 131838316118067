<template>
    <div class="Tree">
        <a-drawer :title="classifyData.length!=0 && classifyData[mouseActive].Title" placement="right" :closable="false" @close="onClose" :visible="visible" width="400">
            <a-form layout="vertical" hideRequiredMark>
                <a-row :gutter="16">
                    <a-col :span="24">
                        <a-form-item label="名称">
                            <a-input style="height: 40px;margin-top:5px;" v-decorator="['name', {rules: [{ required: true, message: 'Please enter user name' }]}]" v-model="alertTile" placeholder="请输入..."/>
                        </a-form-item>
                    </a-col>
                </a-row>

                <a-row :gutter="16">
                    <a-col :span="24">
                        <a-form-item label="标识">
                            <a-input style="height: 40px;margin-top:5px;" v-decorator="['name', {rules: [{ required: true, message: 'Please enter user name' }]}]" v-model="label" placeholder="请输入..."/>
                        </a-form-item>
                    </a-col>
                </a-row>

                <a-row :gutter="16" v-if="setId==''">
                    <a-col :span="24">
                        <a-form-item label="下拉框">
                            <a-select size="large" @change="_getTypeValue" :value="type">
                                <a-select-option :value="item.title" v-for="(item,index) in classifyList">{{item.title}}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                </a-row>

                <a-row :gutter="16" v-if="type==='多行文本'">
                    <a-col :span="24">
                        <a-form-item label="多行文本">
                            <a-input type="textarea" style="min-height: 100px;resize: none" v-model="alertValue" v-decorator="['name', {rules: [{ required: true, message: 'Please enter user name' }]}]" placeholder="请输入..."/>
                        </a-form-item>
                    </a-col>
                </a-row>

                <a-row :gutter="16" v-if="type==='图片上传'">
                    <a-col :span="24">
                        <a-form-item label="图片上传">
                            <img v-if="icoImg&&icoImg[0].FileUrl!=''" v-for="item in icoImg" :src="item.FileUrl" style="width: 40px;height: 40px;display: inline-block;margin-right:5px;" alt="">
                            <div class="upload-btn">
                                <span>+</span>
                                <input type="file" multiple id="icon" @change="_upload">
                            </div>
                        </a-form-item>
                    </a-col>
                </a-row>

                <a-row :gutter="16" v-if="type==='输入框'">
                    <a-col :span="24">
                        <a-form-item label="输入框">
                            <a-input style="height: 40px;" v-model="alertValue" v-decorator="['name', {rules: [{ required: true, message: 'Please enter user name' }]}]" placeholder="请输入..."/>
                        </a-form-item>
                    </a-col>
                </a-row>

                <a-row :gutter="16" v-if="(type==='单选框' || type==='多选框')&& setId==''" style="position: relative">
                    <a-col :span="24">
                        <a-form-item :label="type">
                            <a-icon type="plus-circle" class="plus-circle" @click="_selectList"/>
                            <a-input-group compact style="margin-top:10px;" v-for="(item,index) in selectList">
                                <a-input style="width: 45%" v-model="item.name" placeholder="标签"/>
                                <a-input style="width: 45%" v-model="item.value" placeholder="值" />
                                <a-icon type="close-circle" class="close-circle" @click="_delSeect(index)" v-if="index!=0"/>
                            </a-input-group>
                        </a-form-item>
                    </a-col>
                </a-row>

                <a-row :gutter="16" v-if="type==='单选框' && setId" style="position: relative">
                    <a-col :span="24">
                        <a-form-item :label="type">
                            <a-radio-group @change="_radioSelected" v-model="Selected">
                                <a-radio :value="item.value" v-for="item in alertValue">{{item.name}}</a-radio>
                            </a-radio-group>
                        </a-form-item>
                    </a-col>
                </a-row>

                <a-row :gutter="16" v-if="type==='多选框' && setId" style="position: relative">
                    <a-col :span="24">
                        <a-form-item :label="type">
                            <a-checkbox-group @change="_checkboxSelected" v-model="Selected">
                                <a-checkbox :value="item.value" v-for="item in alertValue">{{item.name}}</a-checkbox>
                            </a-checkbox-group>
                        </a-form-item>
                    </a-col>
                </a-row>

            </a-form>
            <div class="drawer-bottom">
                <a-radio-button value="default" style="width: 120px;text-align: center;margin-right: 10px" @click="_delConfig">{{setId?'删除':'取消'}}</a-radio-button>
                <a-button type="primary" style="width: 120px;text-align: center" @click="_addItemConfig">确认</a-button>
            </div>
        </a-drawer>

        <!--添加配置管理大类-->
        <a-drawer title="添加配置项" placement="right" :closable="false" @close="_hideConfig" :visible="showConfig" width="400">
            <a-form layout="vertical" hideRequiredMark>
                <a-row :gutter="16">
                    <a-col :span="24">
                        <a-form-item label="名称">
                            <a-input style="height: 40px;margin-top:5px;" v-decorator="['name', {rules: [{ required: true, message: 'Please enter user name' }]}]" v-model="addConfig" placeholder="请输入..."/>
                        </a-form-item>
                    </a-col>
                </a-row>
            </a-form>
            <div class="drawer-bottom">
                <a-radio-button value="default" style="width: 120px;text-align: center;margin-right: 10px" @click="_hideConfig">取消</a-radio-button>
                <a-button type="primary" style="width: 120px;text-align: center" @click="_submitConfig">确认</a-button>
            </div>
        </a-drawer>

        <div class="classify">
            <div class="classify-header">配置管理</div>
            <ul class="classify-list">
                <li v-for="(item,index) in classifyData" @click="_mouseActive(index)" :class="mouseActive==index?'ant-menu-item-selected':''">
                    <div class="flex">
                        <div>
                            <div class="border-left" :class="mouseActive==index?'ant-btn-primary':''"></div>
                            <a-icon type="appstore" /> <span>{{item.title}}</span>
                        </div>
                        <!--<div v-show="mouseActive==index" style="display: flex;align-items: center;">-->
                            <!--<a-popconfirm placement="top" cancelText="取消" okText="确定" :visible="delActive==index" @confirm="_removeClassify(item.Id,index)" @cancel="delActive=-1">-->
                                <!--<template slot="title">-->
                                    <!--<p>确定删除么？</p>-->
                                <!--</template>-->
                                <!--<a-icon type="delete" class="icon-btn ant-menu-item-selected" style="margin-left:9px" @click.stop="_maxClassify(index)"/>-->
                            <!--</a-popconfirm>-->
                        <!--</div>-->
                    </div>
                </li>
            </ul>
            <!--<div style="margin: 20px 15px;">-->
                <!--<a-button type="dashed" style="height: 40px" block @click="_showConfig">+ 添加分类</a-button>-->
            <!--</div>-->
        </div>

        <div class="classify classify-item" v-if="classifyData.length!=0">
            <div class="classify-header">{{classifyData[mouseActive].title}}</div>
            <div class="classify-content">
                <a-form layout="vertical" hideRequiredMark>
                    <a-row :gutter="16" v-for="(item,index) in detailList" style="margin-bottom: 15px;">
                        <a-col :span="24">
                            <div style="margin-bottom: 8px;font-size: 15px;" @mouseover="_showSetIcon(index)" @mouseout="showSetIcon=-1">{{item.Title}} <a-icon type="form" style="margin-left:5px;cursor: pointer" class="icon-btn ant-menu-item-selected" v-show="showSetIcon===index" @click="_setConfig(index)"/></div>
                            <img v-if="item.Type=='图片上传'" v-for="tag in item.Value" :src="tag.FileUrl" style="width: 40px;height: 40px;display: inline-block;margin-right:5px;" alt="">
                            <a-input v-if="item.Type=='输入框'" style="height: 40px;cursor: pointer" readonly v-decorator="['name', {rules: [{ required: true, message: 'Please enter user name' }]}]" :value="item.Value" placeholder="请输入..."/>
                            <a-input type="textarea" style="resize: none" v-if="item.Type=='多行文本'" :value="item.Value" readonly placeholder="请输入..."/>
                            <div class="name" v-if="item.Type=='单选框'">
                                <a-radio-group name="radioGroup" disabled v-model="item.Selected">
                                    <a-radio :value="tag.value" v-for="tag in item.Value" selected>{{tag.name}}</a-radio>
                                </a-radio-group>
                            </div>

                            <div class="name" v-if="item.Type=='多选框'">
                                <a-checkbox-group disabled v-if="item.Selected" v-model="item.Selected">
                                    <a-checkbox :value="tag.value" v-for="tag in item.Value">{{tag.name}}</a-checkbox>
                                </a-checkbox-group>
                            </div>
                        </a-col>
                    </a-row>
                </a-form>
                <div class="empty" v-show="detailList.length==0">暂无数据</div>
            </div>
            <div class="created-add ant-btn-primary" @click="_select"><a-icon type="plus" /></div>
        </div>
    </div>
</template>

<script>
    import SingularUpLoad from 'components/UpLoad/SingularUpLoad';
    export default {
        name: "Tree",
        components: {
            SingularUpLoad
        },
        data(){
            return{
                listData: '',
                mouseActive: 0,
                delActive: -1,
                showSetIcon: -1,
                visible: false,
                showConfig: false,
                tabActive: 0,
                drawerTitle: '',
                infoMessage: '',
                showInfoMsg: false,
                childrenName:'',
                parentId: '',
                selectData: '',
                classifyData: [],
                detailList: [],
                addConfig: '',
                classifyList: [
                    {
                        title: '多行文本',
                    },
                    {
                        title: '图片上传',
                    },
                    {
                        title: '输入框',
                    },
                    {
                        title: '单选框',
                    },
                    {
                        title: '多选框',
                    },
                    {
                        title: '文件上传'
                    }
                ],
                type: '多行文本',
                icoImg: [],
                alertValue: '',
                alertTile: '',
                setId: '',
                setIndex: -1,
                label: '',
                selectList: [
                    {
                        name: '',
                        value: '',
                    }
                ],
                Selected: ''
            }
        },
        created(){
            this.$axios.post(1215,{label:'peizhi'},res => {
                if(res.data.code===1){
                    this.classifyData = res.data.data;
                    this._mouseActive(this.tabActive);
                }
            });
        },
        methods:{
            _checkboxSelected(e){
                this.Selected = e;
            },
            _radioSelected(e){
                this.Selected = e.target.value;
            },
            _showSetIcon(i){
                this.showSetIcon = i;
            },
            _delSeect(i){
                this.selectList.splice(i,1);
            },
            _selectList(){
                this.selectList.push({name: '',value: ''});
            },
            _removeClassify(id,i){
                this.setId = id;
                this.setIndex = i;
                let self = this;
                this.$confirm({
                    title: '提示',
                    content: '您确定要删除吗 ?',
                    cancelText: '取消',
                    okText: '确定',
                    confirmLoading: true,
                    onOk () {
                        self.$axios.post(1226, {
                            Id: self.setId,
                        },res => {
                            self.isSet = false;
                            if(res.data.code==1){
                                self.$message.success(res.data.msg);
                                self.classifyData.splice(i,1);
                            }else{
                                self.$message.error(res.data.msg);
                            }
                        });
                    },
                    onCancel () {
                    }
                });
            },
            _maxClassify(i){
                this.delActive = i;
            },
            _delConfig(){
                let self = this;
                this.$confirm({
                    title: '提示',
                    content: '您确定要删除吗 ?',
                    cancelText: '取消',
                    okText: '确定',
                    confirmLoading: true,
                    onOk () {
                        self.$axios.post(1225, {
                            Id: self.setId,
                        },res => {
                            self.isSet = false;
                            if(res.data.code==1){
                                self.$message.success(res.data.msg);
                                self.detailList.splice(self.setIndex,1);
                                self.alertTile = '';
                                self.type = '多行文本';
                                self.alertValue = '';
                                self.visible = false;
                            }else{
                                self.$message.error(res.data.msg);
                            }
                        });
                    },
                    onCancel () {
                    }
                });
            },
            _setConfig(i){
                this.alertTile = this.detailList[i].Title;
                this.type = this.detailList[i].Type;
                this.alertValue = this.detailList[i].Value;
                if(this.detailList[i].Type=='图片上传'){
                    this.icoImg = this.detailList[i].Value
                }
                if(this.detailList[i].Type=='单选框' || this.detailList[i].Type=='多选框'){
                    this.selectList = this.detailList[i].Value
                }
                this.setId = this.detailList[i].Id;
                this.setIndex = i;
                this.label = this.detailList[i].Label;
                this.Selected = this.detailList[i].Selected;
                this.visible = true;
                this.isSet = true;
            },
            _addItemConfig(){
                let value = '';
                if(this.type=='单选框'||this.type=='多选框'){
                    this.alertValue = JSON.stringify(this.selectList);
                }
                if(this.type=='图片上传'){
                    this.alertValue = JSON.stringify(this.alertValue);
                }
                if(this.isSet){
                    this.$axios.post(1224, {
                        Id: this.setId,
                        Title: this.alertTile,
                        Type: this.type,
                        Value: this.alertValue,
                        Label: this.label,
                        Selected: this.type=='多选框'?JSON.stringify(this.Selected):this.Selected,
                        UpdateUserId: this.__USER__.uid,
                        UpdateUserName: this.__USER__.name,
                    },res => {
                        this.isSet = false;
                        if(res.data.code==1){
                            this.$message.success('修改成功');
                            if(this.type=='图片上传' || this.type=='单选框' || this.type=='多选框'){
                                this.detailList[this.setIndex].Value = JSON.parse(this.alertValue);
                            }
                            this.detailList[this.setIndex].Title = this.alertTile;
                            this.detailList[this.setIndex].Type = this.type;
                            this.detailList[this.setIndex].Selected = this.Selected;
                            this.alertTile = '';
                            this.type = '多行文本';
                            this.alertValue = '';
                            this.label = '';
                            this.setId = '';
                            this.setIndex = -1;
                            this.Selected = '';
                            this.visible = false;
                        }else{
                            this.$message.error(res.data.msg);
                        }
                    });
                }else{
                    this.$axios.post(1223, {
                        Title: this.alertTile,
                        Type: this.type,
                        Value: this.alertValue,
                        Pid: this.classifyData[this.mouseActive].Id,
                        CreateUserId: this.__USER__.uid,
                        CreateUserName: this.__USER__.name,
                        Label: this.label,
                        Selected: this.Selected
                    },res => {
                        this.isSet = false;
                        if(res.data.code==1){
                            this.$message.success('操作成功');
                            if(this.type=='图片上传' || this.type=='单选框' || this.type=='多选框'){
                                this.alertValue = JSON.parse(this.alertValue);
                            }
                            this.detailList.push({Title: this.alertTile,Id: res.data.data,Value:this.alertValue,Type:this.type,Label:this.label});
                            this.alertTile = '';
                            this.type = '多行文本';
                            this.alertValue = '';
                            this.label = '';
                            this.setId = '';
                            this.setIndex = -1;
                            this.visible = false;
                            this.Selected = '';
                        }else{
                            this.$message.error(res.data.msg);
                        }
                    });
                }

            },
            _upload(){
                let files = document.getElementById('icon').files;
                this.__uploadFile__(files).then(res=>{
                    this.icoImg = res;
                    this.alertValue = res;
                })
            },
            _getTypeValue(value){
                this.type = value;
                this.alertValue = '';
            },
            _submitConfig(){
                this.$axios.post(1222, {
                    Title: this.addConfig,
                    CreateUserId: this.__USER__.uid,
                    CreateUserName: this.__USER__.name,
                },res => {
                    if(res.data.code===1){
                        this.classifyData.push({Title: this.addConfig,Id: res.data.data.Id})
                    }else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            _showConfig(){
                this.showConfig = true
            },
            _mouseActive(i){
                let self = this;
                this.mouseActive = i;
                this.detailList = [];
                this.$axios.post(1221,{Pid:this.classifyData[i].Id},res => {
                    if(res.data.code&&res.data.code===1){
                        this.detailList = res.data.data;
                        for(let j = 0;j < this.detailList.length;j++){
                            if(self.detailList[j].Type=='图片上传' || self.detailList[j].Type=='单选框' || self.detailList[j].Type=='多选框'){
                                self.detailList[j].Value = JSON.parse(self.detailList[j].Value)
                            }
                            if(self.detailList[j].Type=='多选框'){
                                self.detailList[j].Selected = JSON.parse(self.detailList[j].Selected);
                            }
                        }
                    }
                });
            },
            _tabChange(activeKey){
                this.$axios.get(1221, {
                    pid: this.listData[activeKey].Id
                },res => {
                    if(res.data.code===1){
                        this.treeData = res.data.data;
                        this.showInfoMsg = false;
                    }else{
                        this.treeData = [];
                        this.showInfoMsg = true;
                        this.infoMessage = res.data.msg;
                    }
                });
            },
            _select(selectedKeys,selectedNodes){
                // this.selectData = selectedNodes.selectedNodes[0].data.props;
                // this.drawerTitle = selectedNodes.node.title;
                // this.parentId = selectedKeys;
                this.visible = true;
            },
            _hideConfig(){
                this.showConfig = false;
            },
            onClose() {
                this.visible = false;
                this.alertValue = '';
                this.alertTile =  '';
                this.label = '';
                this.setId =  '';
                this.icoImg = [];
                this.setIndex =  -1;
            },
        }
    }
</script>

<style scoped lang="less">
    .Tree{
        display: flex;
        padding-top: 0;
        min-height: 100%;
    }
    .close-circle{
        margin-top: 4px;
        margin-left: 10px;
        font-size:20px;
        color: #666;
        cursor: pointer;
        vertical-align: middle;
    }
    .plus-circle{
        position: absolute;
        top:-35px;
        right:10px;
        font-size:20px;
        color: #666;
        cursor: pointer;
    }
    .upload-btn{
        width: 40px;
        height: 40px;
        display: inline-block;
        vertical-align: middle;
        margin-left: 5px;
        text-align: center;
        position: relative;
        border: 1px solid #ddd;
        cursor: pointer;
        span{
            transform: translate(-50%,-50%);
            font-size: 35px;
            line-height: 30px;
            color: #666;
        }
        input{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
        }
    }
    .classify{
        width: 350px;
        min-height: 100%;
        background: #fff;
        .classify-header{
            height: 60px;
            line-height: 60px;
            padding-left: 30px;
            border-bottom: 1px solid #F3F3F3;
            font-size: 18px;
        }
        .classify-list{
            margin: 10px 0;
            li{
                height: 50px;
                line-height: 50px;
                padding: 0 30px;
                font-size: 16px;
                border-bottom: 1px solid #F3F3F3;
                cursor: pointer;
                position: relative;
                span{
                    display: inline-block;
                    padding-left: 10px;
                }
                .border-left{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 4px;
                    height: 100%;
                }
            }
        }
    }
    .classify-item{
        width: 450px;
        min-height: 100%;
        overflow-y: scroll;
        background: #F8F9FA;
        position: relative;
        .classify-header{
            position: absolute;
            z-index: 100;
            width: 100%;
            border-bottom: 1px solid #E0E1E2;
            background: #F8F9FA;
        }
        .classify-content{
            min-height: calc(~'100% - 50px');
            padding: 10px 30px;
            padding-top: 70px;
        }
        .created-add{
            position: absolute;
            bottom: 10px;
            right: 10px;
            width: 45px;
            height: 45px;
            line-height: 45px;
            text-align: center;
            border-radius: 50%;
            color: #fff;
            cursor: pointer;
        }
    }
    .drawer-bottom{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border-top: 1px solid #e9e9e9;
        padding: 10px 16px;
        background: #fff;
        text-align: left;
    }
</style>